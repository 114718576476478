import './App.css';
//import './App copy.css';
import { useEffect, useState } from 'react';

function App() {
  const [data, setData] = useState([]);
  const [Product_ID, setProductid] = useState('');
  const [Service_SKU, setServicesku] = useState('');
  const [updatedDate, setUpdatedDate] = useState('');

  const fetchTimestamp = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL_TIMESTAMP); // タイムスタンプのエンドポイントにGETリクエスト
    const text = await response.text();
    setUpdatedDate(text); // 取得したタイムスタンプを状態にセット
  };

  useEffect(() => {
    fetchTimestamp(); // 初回レンダリング時にタイムスタンプを取得
  }, []);

  const handleSearch = () => {
    fetch(process.env.REACT_APP_BACKEND_URL_SEARCH,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Product_ID, Service_SKU }),
    })
      .then((res) => res.json())
//      .then((data) => setData(data));
      .then((data) => {
        // 数値をカンマ区切りでフォーマットする
        const formattedData = data.map(item => ({
          ...item,
          Nihon_USD_GPL_OLD: item.Nihon_USD_GPL_OLD.toLocaleString(),
          Nihon_USD_GPL_NEW: item.Nihon_USD_GPL_NEW.toLocaleString(),
          Nihon_YEN_GPL_OLD: item.Nihon_YEN_GPL_OLD.toLocaleString(),
          Nihon_YEN_GPL_NEW: item.Nihon_YEN_GPL_NEW.toLocaleString(),
        }));
        setData(formattedData);
      });
  };

  const handleDownload = () => {
    fetch(process.env.REACT_APP_BACKEND_URL_DOWNLOAD,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Product_ID, Service_SKU }),
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'results.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);  // Clean up after download
        document.body.removeChild(a);
      });
  };

  const handleClear = () => {
    setProductid('');
    setServicesku('');
    setData([]);
  };

  return (
    <div className="App">
      <h1>Price Change History Search Tool</h1>
      <div>
        <text>{updatedDate}</text> {/* タイムスタンプを表示 */} <br/>
        <input
          type="text"
          placeholder="Product ID"
          value={Product_ID}
          onChange={(e) => setProductid(e.target.value)}
        />
        <input
          type="text"
          placeholder="Service SKU"
          value={Service_SKU}
          onChange={(e) => setServicesku(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
        <button onClick={handleClear}>Clear</button>
        <button onClick={handleDownload}>CSV Export</button> 

      </div>
      <table>
        <thead>
          <tr>
            <th>Product Family</th>
            <th>Product ID</th>
            <th>Service SKU</th>
            <th>Old Price USD</th>
            <th>New Price USD</th>
            <th>Old Price JPY</th>
            <th>New Price JPY</th>
            <th>Announce Date</th>
            <th>Effective Date</th>
            <th>Note</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.Product_Family}</td>
              <td>{row.Product_ID}</td>
              <td>{row.Service_SKU}</td>
              <td>{row.Nihon_USD_GPL_OLD}</td>
              <td>{row.Nihon_USD_GPL_NEW}</td>
              <td>{row.Nihon_YEN_GPL_OLD}</td>
              <td>{row.Nihon_YEN_GPL_NEW}</td>
              <td>{row.Announce_Date}</td>
              <td>{row.New_Price_Effective_Date}</td>
              <td>{row.Note}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;
